.sidebar {
  width: 300px;
  background-color: white !important;
  padding: 20px;
  border-right: 1px solid #ddd;
  position: relative;
  height: auto;
  overflow-y: auto;
  transition: left 0.3s ease;
}

.sidebar.open {
  left: 0;
}

.close-sidebar {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #333;
}

.results {
  margin-bottom: 20px;
}

.results h2 {
  margin: 0;
  color: black !important;
  text-align: center;
  font-size: 27px;
  font-weight: 600;
}

.filter-card {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.filter-header {
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  padding: 20px 10px;
  border-radius: 5px;
}

.filter-header h3 {
  margin: 0;
  font-size: 16px;
}

.info-icon {
  background-color: #00aaff;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
}

.slider-container {
  padding: 10px 0;
}

.price-labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.price-labels span {
  background-color: #00aaff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}

.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #00aaff;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
}

.checkbox input[type="checkbox"]:checked::before {
  content: "✔";
  color: #00aaff;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkbox label {
  flex-grow: 1;
  margin: 0;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.checkbox span {
  background-color: #f0f0f0;
  color: #333;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.coverage-section {
  margin-bottom: 20px;
}

.coverage-section h4 {
  margin: 0 0 10px 0;
}

.coverage-options {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.coverage-options button {
  border: none;
  background-color: #00aaff;
  border-radius: 50% !important;
  color: #bab4b4;
  padding: 20px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
  margin-bottom: 0.8rem;
}

.coverage-options button:hover {
  transform: translateY(-2px);
  background-color: #0077aa;
}

.coverage-options button {
  margin: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.coverage-options button.active {
  background-color: #00aaff;
  color: #fff;
}

.scroll-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scroll-button {
  border: none;
  background-color: #f0f0f0;
  color: #000;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.scroll-bar {
  flex-grow: 1;
  height: 10px;
  background-color: #ddd;
  margin: 0 10px;
  border-radius: 5px;
}

.surpluses-info p {
  margin: 0;
}

.sidebar-toggle {
  display: none;
  position: fixed;
  bottom: 15px !important;
  left: 0;
  width: 100%;
  height: auto !important;
  padding: 0.7rem 0;
  background-color: #00aaff;
  color: white;
  border: none;
  border-radius: 0;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  z-index: 1000;
  animation: slideUp 2s infinite;
  border-radius: 5px;
}

@keyframes slideUp {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .sidebar.open {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow-y: auto;
  }

  .close-sidebar {
    display: block;
  }

  .sidebar-toggle {
    display: block;
  }
}
