/* Modal Overlay */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Container */
.custom-modal {
  width: 80%;
  max-width: 1000px;
  max-height: 90vh;
  background: white;
  padding: 20px;
  border-radius: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1001;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Close Button */
.close-button {
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1002;
}

/* Modal Header */
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.logo {
  height: 100px;
  width: 200px;
  margin-bottom: 10px;
}

.modal-title-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.modal-price {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.modal-price span {
  font-size: 16px;
  margin-right: 5px;
}

.modal-price h2 {
  font-size: 24px;
  margin: 0;
  color: #d30b40;
}

/* Request Button */
.request-button {
  background: #d30b40;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
  margin-top: 10px;
}

.request-button:hover {
  transform: translateY(-2px);
  background-color: #a50a30;
}

/* Modal Body */
.modal-body {
  flex-grow: 1;
}

/* Tabs Section */
.modal-tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.modal-tabs button {
  background: #f0f0f0;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: color 0.2s ease, background-color 0.2s ease;
  font-size: 16px;
  display: flex;
  align-items: center;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    background-color: #f0f0f0;
  }
  50% {
    transform: scale(1.05);
    background-color: #e0e0e0;
  }
}

.modal-tabs button.active {
  color: #d30b40;
  font-weight: bold;
}

.modal-tabs button:hover {
  color: #a50a30;
}

.modal-tabs .fa-icon {
  margin-right: 5px;
}

/* Tab Content */
.tab-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.features,
.contract-content {
  display: flex;
  flex-direction: column;
}

.features h4,
.contract-content h3 {
  margin-bottom: 10px;
}

.features p,
.contract-content p {
  margin-bottom: 10px;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tab-content {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
  overflow: scroll;
}

.table-container th,
.table-container td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table-container th {
  background-color: #f4f4f4;
}

/* Benefits Section */
.benefits {
  width: 100%;
  margin-bottom: 20px;
}

.benefits-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.benefit-card {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.benefit-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.benefit-card .icon {
  margin-right: 10px;
  color: #4caf50;
}

.benefit-card .icon.ambulance {
  color: #ff9800;
}

.benefit-card .icon.medical {
  color: #2196f3;
}

.benefit-card .icon.mountain {
  color: #9c27b0;
}

.additional-benefits {
  margin-top: 20px;
}

.additional-benefits ul {
  list-style: none;
  padding: 0;
}

.additional-benefits ul li {
  margin-bottom: 5px;
}

/* Free Choice Coverage Section */
.free-choice-coverage {
  margin-top: 20px;
}

.coverage-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.coverage-card {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.coverage-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.coverage-card .icon {
  margin-right: 10px;
  color: #4caf50;
}

.coverage-card .icon.ambulance {
  color: #ff9800;
}

.coverage-card .icon.medical {
  color: #2196f3;
}

.coverage-info {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  flex-wrap: wrap;
}

.info-card {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.info-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.info-card .icon {
  margin-right: 10px;
  color: #4caf50;
}

.info-card .icon.arrow {
  color: #ff9800;
}

.info-card .icon.baby {
  color: #2196f3;
}

/* Contract Section */
.contract-content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-wrap: wrap;
}

.contract-left,
.contract-right {
  flex: 1;
}

.contract-right {
  margin-left: 20px;
}

/* PDF Download Section */
.pdf-download {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.pdf-section {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.download-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.download-button:hover {
  transform: translateY(-2px);
  background-color: #0056b3;
}

/* Form Section */
.form-section {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.form-section label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-section input,
.form-section textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-section button {
  background: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.form-section button:hover {
  transform: translateY(-2px);
  background-color: #388e3c;
}

/* Responsive Design */
@media (max-width: 768px) {
  .custom-modal {
    width: 90%;
    max-width: 500px;
  }

  .modal-header {
    flex-direction: column;
    align-items: center;
  }

  .modal-title-price {
    align-items: center;
    text-align: center;
  }

  .modal-price {
    flex-direction: column;
    align-items: center;
  }

  .modal-tabs {
    flex-direction: column;
    gap: 10px;
  }

  .modal-tabs button {
    width: 100%;
  }

  .content-wrapper,
  .contract-content {
    flex-direction: column;
    align-items: center;
  }

  .table-container,
  .benefits,
  .score-left,
  .score-right,
  .pdf-download,
  .form-section {
    width: 100%;
  }

  .score-summary {
    flex-direction: column;
    width: 100%;
  }

  .coverage-details,
  .coverage-card,
  .info-card {
    flex-direction: column;
    width: 100%;
  }

  .contract-left,
  .contract-right {
    width: 100%;
    margin-left: 0;
  }
}
.contract-section {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.contract-section h3 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
}

.contract-section p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #555;
}

.contract-section .download-button {
  display: inline-block;
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contract-section .download-button:hover {
  background-color: #0056b3;
}

.contract-section .form-group {
  margin-bottom: 15px;
}

.contract-section .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #333;
}

.contract-section .form-group input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
}

.contract-section .submit-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contract-section .submit-button:hover {
  background-color: #218838;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 18px;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table th {
  background-color: #f2f2f2;
  color: #333;
  text-align: left;
}

.styled-table td {
  background-color: #f9f9f9;
  border-bottom: 1px solid #dddddd;
}

.styled-table tr:nth-child(even) td {
  background-color: #f3f3f3;
}

.styled-table tr:last-child td {
  border-bottom: 2px solid #009879;
}

.styled-table tr.active-row {
  font-weight: bold;
  color: #009879;
}

.additional-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.info-item {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.info-item p {
  font-size: 1rem;
  color: #333333;
}
.modal-tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dcdcdc; /* Line below the button section */
}

.modal-tabs button {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.modal-tabs button.active {
  background-color: #d9534f !important;
  color: white;
}

.modal-tabs button .fa-icon {
  margin-right: 10px; /* Add space between icon and text */
}

.modal-tabs button:hover {
  background-color: #d9534f !important;
  color: white;
}

.modal-tabs button:focus {
  outline: none;
}
.plan-coverage-section {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.plan-coverage-section h3 {
  font-size: 22px;
  margin-bottom: 15px;
  color: #333;
}

.plan-coverage-section p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #555;
}

.coverage-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.coverage-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  width: 48%;
  display: flex;
  align-items: center;
}

.coverage-item .icon {
  font-size: 24px;
  margin-right: 10px;
  color: #007bff;
}

.coverage-info h4 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.coverage-info p {
  margin: 0;
  font-size: 14px;
  color: #777;
}

.coverage-percentage {
  margin-left: auto;
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
}

.less-coverage {
  margin-top: 20px;
  font-size: 16px;
  color: #333;
}

.less-coverage ul {
  margin: 10px 0 0 20px;
  padding: 0;
  list-style-type: disc;
}

.less-coverage li {
  margin-bottom: 5px;
}

.additional-info {
  margin-top: 20px;
}

.info-item {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 8px;
}
