/* General Styles */
.the-filter {
  padding: 10px 0;
  box-sizing: border-box;
  z-index: 1000;
}

.sky-blue {
  color: #40b4e5;
}

.header-box {
  margin-bottom: 15px;
}

.image-fade-container {
  position: relative;
  display: inline-block;
}

.image-fade-container img {
  display: block;
  width: 100%;
}

/* Filter Section */
.new-filter {
  max-width: 100%;
  padding: 15px;
  border-radius: 0px 0px 5px 5px;
  margin: 0;
  background-color: #f8f9fa;
}

.input-filter {
  margin-bottom: 10px;
}

.form-control,
.form-select {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
}

.btn {
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 5px;
}

.add-btn {
  background-color: #5bc0de;
  color: white;
  display: flex;
  align-items: center;
}

.add-btn i {
  margin-right: 5px;
}

.calc-btn {
  background-color: #007bff;
  color: white;
}

.calc-btn:hover,
.add-btn:hover {
  opacity: 0.8;
}

/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.btn-dropdown {
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
}
.dropdown-menu {
  width: 100%;
  max-height: 250px;
  overflow: scroll;
  overflow-x: hidden;
}
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 450px;
  max-width: 90%;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
}
.show-filters-btn {
  width: 100%;
  background-color: #46b8da;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.insured-section {
  margin-top: 20px;
}

.insured-member {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
}

.input-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 10px;
}

.input-group label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
}

.add-insured-btn {
  width: 100%;
  padding: 10px;
  background-color: #5bc0de;
  color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: pointer;
  font-weight: bold;
}

.add-insured-btn:hover {
  background-color: #46b8da;
}

.remove-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
}

.remove-btn:hover {
  background-color: #c82333;
}

.modal-calc-btn {
  width: 100%;
  padding: 10px;
  background-color: #d30b40;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .new-filter {
    padding: 10px;
  }

  .input-filter,
  .form-control,
  .form-select {
    margin-bottom: 10px;
    width: 100%;
  }

  .header-box .row {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .image-fade-container {
    width: 100%;
  }

  .new-filter .row {
    flex-direction: column;
    align-items: stretch;
  }

  .add-btn,
  .calc-btn {
    width: 100%;
    margin-bottom: 10px;
  }

  .modal-content {
    width: 90%;
    max-width: 100%;
  }
}
