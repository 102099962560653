.app-container {
  overflow-x: hidden;
}
.app {
  display: flex;
}

.main-content {
  flex: 1;
  padding: 20px;
}

/* Sidebar.css */
.sidebar {
  width: 300px;
  background-color: #f5f5f5;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.results {
  margin-bottom: 20px;
}

.results h2 {
  margin: 0;
  color: #00aaff;
}

.filter-card {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.filter-header h3 {
  margin: 0;
  font-size: 16px;
}

.info-icon {
  background-color: #00aaff;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
}

.slider-container {
  padding: 10px 0;
}

.price-labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.price-labels span {
  background-color: #00aaff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}

.clinic-filters,
.isapres-filters {
  display: flex;
  flex-direction: column;
}

.clinic-filters input[type="text"] {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox input[type="checkbox"] {
  margin-right: 10px;
}

.checkbox label {
  flex-grow: 1;
}

.checkbox span {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
}

.coverage-section {
  margin-bottom: 20px;
}

.coverage-section h4 {
  margin: 0 0 10px 0;
  font-size: 14px;
}

.coverage-options {
  display: flex;
  overflow-x: scroll;
  margin-bottom: 10px;
}

.coverage-options button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.coverage-options button:hover {
  background-color: #00aaff;
  color: #fff;
}

.scroll-container {
  display: flex;
  align-items: center;
}

.scroll-button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.scroll-button:hover {
  background-color: #00aaff;
  color: #fff;
}

.scroll-bar {
  flex-grow: 1;
  height: 5px;
  background-color: #ddd;
  border-radius: 5px;
}

.surpluses-info p {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  color: #666;
  margin: 0;
}

/* TopFilters.css */
.top-filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.left-filters {
  display: flex;
  align-items: center;
}

.score-button {
  background-color: #00ff00;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;
}

.right-filters {
  display: flex;
  align-items: center;
}

.grid-button,
.list-button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  margin-left: 5px;
  cursor: pointer;
}

/* ContentCard.css */
.content-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.tag {
  background-color: #00aaff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}

.logo {
  height: 30px;
}

.compare {
  display: flex;
  align-items: center;
}

.compare input {
  margin-right: 5px;
}

.card-body {
  margin-bottom: 20px;
}

.details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.detail {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex: 1 1 45%;
}

.icon {
  margin-right: 5px;
}

.score-section {
  margin-top: 20px;
}

.score {
  font-size: 18px;
}

.score-value {
  background-color: #00ff00;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  text-align: right;
}

.price h2 {
  margin: 0;
}

.request-button,
.detail-button {
  background-color: #ff00ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
}

.detail-button {
  background-color: #f0f0f0;
  color: #000;
}
/* App.css */
.main-container {
  display: flex;
  flex-direction: column;
}

.content-with-sidebar {
  display: flex;
  flex-direction: row;
  position: relative;
}

/* .sidebar-toggle {
  display: none;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1001;
  background-color: #00aaff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
} */

.main-content {
  flex: 1;
  padding: 20px;
  transition: margin-left 0.3s ease;
}

.main-content.sidebar-open {
  margin-left: 300px;
}

@media (max-width: 768px) {
  .sidebar-toggle {
    display: block;
  }

  .main-content {
    margin-left: 0;
  }

  .main-content.sidebar-open {
    margin-left: 0;
  }

  .sidebar.open {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
}
.app-container {
  display: flex;
  flex-direction: column;
}

.main-content {
  display: flex;
  flex-direction: row;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.top-filters {
  margin-bottom: 20px;
}

.card-list {
  flex-grow: 1;
}
