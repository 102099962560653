.card-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.no-cards {
  text-align: center;
  padding: 3rem 0;
  color: red;
  font-size: 30px;
  font-weight: bold;
}

.loader {
  position: relative;
  width: 60px;
  height: 20px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
}

.loader .dot {
  width: 15px;
  height: 15px;
  background-color: #3498db;
  border-radius: 50%;
  animation: bounce 1.5s infinite ease-in-out;
}

.loader .dot:nth-child(2) {
  animation-delay: 0.3s;
}

.loader .dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.pagination-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-button:not(:disabled):hover {
  background-color: #0056b3;
}

.pagination-info {
  margin: 0 10px;
  font-weight: bold;
  font-size: 16px;
}

/* Loader styles */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.dot {
  width: 16px;
  height: 16px;
  margin: 0 5px;
  background-color: #007bff;
  border-radius: 50%;
  animation: dot-blink 1.4s infinite ease-in-out both;
}

.dot:nth-child(2) {
  animation-delay: -0.32s;
}

.dot:nth-child(3) {
  animation-delay: -0.16s;
}

@keyframes dot-blink {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
