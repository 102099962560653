/* Footer.css */
.footer {
  background-color: #007bff;
  color: white;
  padding: 40px 0;
}

.footer h4 {
  margin-bottom: 20px;
}

.footer-list {
  list-style: none;
  padding: 0;
}

.footer-list li {
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.footer-list li svg {
  margin-right: 10px;
}

.footer-icons {
  margin-top: 20px;
}

.footer-icons svg {
  font-size: 24px;
  margin-right: 10px;
  cursor: pointer;
}

.footer-icons svg:hover {
  color: #ffc107;
}

@media (max-width: 767px) {
  .footer-list li {
    font-size: 12px;
  }

  .footer-icons svg {
    font-size: 20px;
  }
}
