.why-hire-section {
  padding: 50px 15px;
  border-radius: 10px;
}

.why-hire-image-container {
  text-align: center;
}

.why-hire-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.why-hire-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.why-hire-list {
  list-style: none;
  padding: 0;
}

.why-hire-list-item {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #555;
}

.why-hire-check-icon {
  color: #28a745;
  font-size: 1.5rem;
  margin-right: 10px;
}

.why-hire-strong {
  color: #d30b40;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .why-hire-title {
    font-size: 1.5rem;
  }

  .why-hire-list-item {
    font-size: 1rem;
  }

  .why-hire-check-icon {
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .why-hire-section {
    padding: 30px 10px;
  }

  .why-hire-title {
    font-size: 1.2rem;
  }

  .why-hire-list-item {
    font-size: 0.9rem;
  }

  .why-hire-check-icon {
    font-size: 1rem;
  }
}
