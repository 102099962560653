.content-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  height: 320px;
  box-sizing: border-box;
  z-index: 0;
}

.card-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 2;
}

.card-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  border-left: 1px solid #e0e0e0;
  flex: 1;
}

.top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tag {
  background-color: #e0f7fa;
  color: #0277bd;
  padding: 3px 6px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.logo {
  width: 100px;
  height: 40px;
  margin-bottom: 5px;
  margin-top: 1rem;
}

.right-items {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.compare {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 0 5px;
}

.compare-checkbox {
  margin-right: 5px;
}

.score-section {
  display: flex;
  align-items: center;
  padding: 0 10px;
  background-color: white;
}

.score-value {
  font-size: 14px;
  font-weight: bold;
  color: #4caf50;
  margin-left: 5px;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#h2 {
  margin: 5px 0;
  font-size: 14px;
}

.details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  border-top: 1px solid #e0e0e0;
}

.detail {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
}

.detail .icon {
  margin-right: 5px;
  font-size: 16px;
  color: #00aaff;
  transition: color 0.3s;
}

.detail div {
  display: flex;
  flex-direction: column;
}

.detail .detail-heading {
  font-weight: bold;
}

.detail:hover .icon {
  color: #007bb5;
}

.price-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 5px;
}

.price-section span {
  font-size: 12px;
  color: #777;
}

.price-section h2 {
  margin-right: 30px;
  color: #000;
  text-align: center;
  font-size: 27px;
  font-weight: bolder;
}

.request-button,
.detail-button {
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
  margin-bottom: 1rem;
  width: 90%;
  animation: buttonAnimation 2s infinite;
}

@keyframes buttonAnimation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.request-button {
  background-color: red !important;
  color: #fff;
}

.request-button:hover {
  transform: translateY(-2px);
  background-color: #a44366 !important;
}

.detail-button {
  background-color: #e0e0e0;
  color: #000;
}

.detail-button:hover {
  transform: translateY(-2px);
  background-color: #bdbdbd;
}

@media (max-width: 1024px) {
  .content-card {
    flex-direction: column;
    height: auto;
  }

  .card-right {
    align-items: center;
    border-left: none;
    border-top: 1px solid #e0e0e0;
    padding-top: 10px;
    margin-top: 10px;
  }

  .price-section {
    align-items: center;
  }

  .request-button,
  .detail-button {
    width: 80%;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .content-card {
    padding: 10px;
  }

  .top-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .right-items {
    margin-top: 10px;
  }

  .details {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .content-card {
    padding: 10px;
  }

  .top-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .right-items {
    margin-top: 10px;
  }

  .details {
    grid-template-columns: 1fr;
  }
}
