.top-filters-container {
  position: relative;
}

.top-filters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.filter-options {
  display: flex;
  align-items: center;
  margin-right: 20px; /* Margin between the fields and the button */
}

.filter-options span {
  margin: 0 10px;
}

.filter-options select {
  margin: 0 10px;
  padding: 5px;
}

/* Ensure the button appears to the right of the filter options */
.score-button {
  background-color: #18c110;
  color: #c2ffc0;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
} .score-button:hover{
color: #fff;
    background: #2aa154;
}

/* Modal Styles */
.score-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.score-modal {
  width: 80%;
  max-width: 600px;
  max-height: 80vh;
  background: white;
  padding: 20px;
  border-radius: 10px;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-content h2 {
  margin-top: 0;
  padding: 0 20px;
}

.modal-content p {
  line-height: 1.6;
  padding: 20px;
}

/* Media Queries */
@media (max-width: 768px) {
  .top-filters {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  .filter-options {
    margin: 10px 0;
    flex-direction: column;
    width: 100%;
    margin-right: 0; /* Adjust for mobile */
  }

  .filter-options span,
  .filter-options select {
    margin: 5px 0;
    width: 100%;
  }

  .score-button {
    margin-top: 10px;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .score-button,
  .filter-options select {
    padding: 8px 10px;
    font-size: 14px;
    width: 100%;
  }

  .modal-content h2,
  .modal-content p {
    padding: 0 10px;
  }
}
